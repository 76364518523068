#calendarTitle {
  position: absolute;
  top: 56px;
  left: 10px;
  z-index: 12;
  font-size: 25px;
  text-align: center;
}
.inZoom{
  #calendarTitle {
    position: absolute;
    top: 25px;
    transition: all 0.8s;
    font-size: 12px;
    padding: 0 15px;
    z-index: 10;
  }
}

.calendarContainer{
  width: 118px;
  height: 164px;
  top: 432px;
  left: 1696px;
  span{
    position: absolute;
    top: 36px;
    left: 0;
    width: 114px;
    text-align: center;
    font-family: AmaticSC;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    color: #000105;
  }
  .calendar{
    width: 100%;
  }
  .coverTitle, h3{
    text-align: center;
    font-family: AmaticSC;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    color: #006b96;
    margin: 0;
  }
  h3{
    font-size: 11px;
    width: 103px;
  }
  .calendarContent{
    position: absolute;
    top: 26px;
    left: 0;
    width: 118px;
    padding: 0 10px;
  }
  .frameCover{
    width: 105px;
    height: 140px;
    top: 23px;
    left: 9px;
    z-index: 11;
    h3{
      font-size: 17px;
      width: 103px;
    }
  }
  form {
    width: 70px;
    margin: 4px auto 0 auto;
  }
  #calendarIntroduce{
    position: absolute;
    top: 53px;
    left: 9px;
    width: 106px;
    padding: 0 3px;
    height: 100px;
    overflow: scroll;
    font-size: 6px;
    font-family: Teko;
    color:  #000105;
    &::-webkit-scrollbar-thumb {
      background: #635CC6;        /* color of the tracking area */
    }
    p{
      margin: 3px 0;
    }
    ul{
      padding-left: 15px;
    }
    button {
      width: 40px;
      display: block;
      margin: 0 auto;
      background-color: #4fbce6;
      box-shadow: -1px 1.1px 0 0 rgba(14, 70, 92, 0.44);
      font-family: Teko;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      font-size: 8px;
      letter-spacing: normal;
      text-align: center;
      color: #6b3b87;
      border: none;
    }
  }
  .arrow{
    position: absolute;
    opacity: 1;
    top: 22px;
    left: 13px;
    width: 18px;
    height: 10px;
    z-index: 10;
    cursor: pointer;
    svg{
      width: 100%;
    }
    &.next {
      left: 92px;
    }
  }
}

#calendar{
  .selectDate {
    top: -3px;
    margin-bottom: 8px;
    font-family: Teko;
    font-size: 6px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.53;
    letter-spacing: normal;
    text-align: center;
    color: #000105;
  }
  .emailSuccessText {
    width: 106px;
    height: 127px;
    top: 26px;
    left: 9px;
    background: rgba(235, 235, 235, 0.9);
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }
  #rcCalendarContainer{
    position: absolute;
    width: 105px;
    height: 88px;
    bottom: 18px;
    left: 10px;
    transition: all 0.8s ease-out;
    .react-calendar{
      background: #EAEAEC;
      border: none;
    }
  }
  .react-calendar__tile{
    padding: 2px;
    font-size: 6px;
    line-height: 6px;
    border: 1px solid  #EAEAEC!important;
    &--active {
      background: none;
      color: initial;
      border: 1px solid #006b95 !important;
      border-radius: 50%;
    }
  }
  .calendarStep{
    position: absolute;
    width: 105px;
    height: 88px;
    bottom: 18px;
    left: 10px;
  }
  .calendarStep2{
    top: 24px;
    h5{
      font-family: AmaticSC;
      font-size: 8px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.16;
      letter-spacing: normal;
      text-align: center;
      color: #006b96;
      margin: 0;
    }
  }
  .react-calendar__navigation button{
    min-width: 10px;
  }
  .react-calendar__navigation{
    height: 10px;
    margin-bottom: 9px;
  }
  span{
    font-size: 6px;
    top: 6px;
    left: -5px;
    font-family: AmaticSC;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.67;
    letter-spacing: normal;
    text-align: center;
    color: #000105;
  }
  .react-calendar__navigation{
    span{
      font-size: 9px;
      line-height: 7px;
    }
  }
  abbr{
    font-family: AmaticSC;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.67;
    letter-spacing: normal;
    text-align: center;
  }
  .react-calendar__month-view__weekdays{
    margin-bottom: 6px;
  }
  .react-calendar__tile:disabled{
    background-color: #e0e0e0;
  }
}
.isMobile{
  #calendar .react-calendar__navigation span{
    font-size: 9px !important;
  }
  #calendar .calendarStep2 h5{
    font-size: 8px !important;
  }
}
