@import 'font.scss';
@import 'clock.scss';
@import 'lightSwitch.scss';
@import 'mobileFrame.scss';
@import 'consultation.scss';
@import 'gallery.scss';
@import 'modal.scss';
@import 'siteWalkThrough.scss';
$transition: 0.5s ease-out;

* {
  box-sizing: border-box;
  -webkit-text-size-adjust: none;
}

body {
  margin: 0;
  overflow: scroll;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
body::-webkit-scrollbar {
  display: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

svg{
  width: 100%;
}

div{
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background: #fe4040;        /* color of the tracking area */
  }
}

body,
.App {
  width: 1920px;
  height: 1080px;
  position: relative;
}

.App{
  overflow: hidden;
}

.hide{
  display: none !important;
}

.logo{
  position: absolute;
  left: 953px;
  top: 70px;
  width: 175px;
  overflow: hidden;
}

#container{
  width: 1920px;
  height: 1080px;
  overflow: hidden;
  padding: 0;
  background-image: url('/images/background.png');
  background-color: #fff;
  mix-blend-mode: saturation;
  background-size: cover;;
  object-fit: contain;
  //opacity: 0.51;
  mix-blend-mode: multiply;
  /* background-image: url("./images/wall.jpg");
  background-size: contain; */
  transition: $transition;
  &.isMobile{
    p, li, span, a{
      font-size: 1.4em !important;
    }
    h5{
      font-size: 14px;
    }
    h5 span{
      font-size: 13px !important;
    }
  }
}

.closeContentButton{
  position: absolute;
  width: 30px;
  height: 30px;
  display: none;
  z-index: 202;
  color: #000;
}

.contentContainer{
  padding: 5em 0;
  overflow-y: scroll;
  margin-bottom: 5px;
  h5 {
    text-align: left;
    margin: 0 auto;

    span {
      color: #fff;
      font-family: Teko;
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 0.66px;
      background-color: #fe4040;
      padding: 3px 4px 2px 4px;
      margin-left: 11px;
      white-space: pre-wrap;
    }
  }
  .text{
    font-family: Teko;
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    color: #fe4040;
    padding: 1em 1em;
  }
  ul, ol{
    padding-left: 12px;
  }
  .images{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .imageBox{
    padding: 0 5px;
    img{
      cursor: pointer;
    }
  }
  img {
    width: 100%;
  }
  p {
    margin: 0;
  }
}

.frameCover {
  width: calc(100% - 22px);
  height: calc(100% - 22px);
  position: absolute;
  background-color: rgba(233,233,235, 0.97);
  z-index: 9;
  top: 11px;
  left: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.8s ease-out;
  img {
    width: 75px;
  }
}

#closeElement{
  position: absolute;
  top: 491px;
  left: 1410px;
  width: 10px;
  height: 10px;
  background-color: transparent;
}
.react-joyride__spotlight{
  z-index: -1;
}

#wopes{
  .frameCover{
    img{
      width: 50px;
    }
  }
}

.darkLayer{
  width: 100%;
  height: 100%;
  position: fixed;
  background: #30322F;
}

.linksContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 10px;
  .link {
    border-radius: 11px;
    background-color: #fe4040;
    padding: 2px 4px 2px 4px;
    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      font-family: Montserrat;
      font-size: 5px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      text-transform: uppercase;
      line-height: 1;
      letter-spacing: 0px;
      text-align: center;
      color: #fff;
      span{
        margin-right: 1px;
      }
    }
    &:nth-child(1){
      background-color: #ffb000;
    }
    &:nth-child(2) {
      background-color: #fe4040;
    }
    &:nth-child(3) {
      background-color: #009C7A;
    }
  }
}

.opacity0 {
  opacity: 0;
}
.opacity1{
  opacity: 1;
}

.monitorContent{
  width: 342px;
  height: 194px;
  padding-left: 10px !important;
  padding-right: 10px !important;
  background-color: #e9e9eb;
  .frameCover{
    width: 100%;
    z-index: 0;
    img{
      width: 150px;
    }
  }
  .contentContainer{
    overflow-y: scroll;
    h5 {
      span{
        box-shadow: 1px 2px 1px 1px #000000;
      }
    }
    .textAndImage {
      display: flex;
      flex-direction: row;
      img{
        width: 82px;
        height: 82px;
        border-radius: 50%;
        margin-right: 12px;
      }
      p{
        padding: 2px 0;
      }
    }
    .socialBar {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      margin-top: 10px;
    }
  }
  b{
    color: #000;
    font-weight: normal;
  }
}

.zoomLevel{
  cursor: pointer;
}

.zoomable {
  position: absolute;
  padding: 3px;
  overflow: hidden;
  cursor: pointer;
  & > *{
    font-size: 0.3rem;
  }
  &.inZoom{
    cursor: default;
  }
}

.player {
  position: absolute;
  top: 765px;
  left: 1074px;
  z-index: 200;
  button {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    background-color: #BF0020;
  }
}

.yourSite{
  font-family: AmaticSC;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3c3c3b;
}

.arrow {
  position: fixed;
  top: 40%;
  transition: $transition;
  z-index: 99;
  color: #09061C;
  opacity: 0;
}

.frame {
  display: flex;
  flex-direction: column;
  text-align: center;
  text-decoration:none;
  color:#000;
  box-sizing:border-box;
  text-align:center;
  padding: 22px;
}

.greenPBig {
  width: 286px;
  height: 418px;
  padding: 35px;
  background-image: url("/images/png/kepkeret-1.png");
  .text {
    font-size: 12px;
    padding: 2em 0;
    h5 {
      margin: 6px auto;
      font-size: 13px;
    }
  }
}

.greenP{
  width: 254px;
  height: 369px;
  background-image: url("/images/png/kepkeret-3.png");
}

.blueL{
  width: 361px;
  height: 247px;
  background-image: url("/images/png/kepkeret-6.png");
}

.purpleL{
  width: 224px;
  height: 154px;
  background-image: url("/images/png/kepkeret-5.png");
}

.yellowP{
  width: 211px;
  height: 307px;
  background-image: url("/images/png/kepkeret-4.png");
}

.greenFrame{
  color: #017372;
  .contentContainer{
    h5 span{
      background-color: #017372;
    }
    .text {
      color: #017372;
    }
    &::-webkit-scrollbar-thumb {
      background: #017372;        /* color of the tracking area */
    }
  }
  .linksContainer{
    .link{
      &:nth-child(1){
        background-color: #017372;
      }
      &:nth-child(2) {
        background-color: #00AB7D;
      }
      &:nth-child(3) {
        background-color: #009C7A;
      }
    }
  }
}

.blueFrame{
  color: #0097BA;
  .contentContainer{
    h5 span{
      background-color: #0097BA;
    }
    .text {
      color: #005182;
    }
    &::-webkit-scrollbar-thumb {
      background: #0097BA;        /* color of the tracking area */
    }
  }
  .linksContainer{
    .link{
      &:nth-child(1){
        background-color: #0097BA;
      }
      &:nth-child(2) {
        background-color: #005182;
      }
      &:nth-child(3) {
        background-color: #005182;
      }
    }
  }
}

.purpleFrame{
  color: #FFB000;
  .contentContainer{
    h5 span{
      background-color: #FFB000;
    }
    .text {
      color: #635CC7;
    }
    &::-webkit-scrollbar-thumb {
      background: #FFB000;        /* color of the tracking area */
    }
  }
  .linksContainer{
    .link{
      &:nth-child(2) {
        background-color: #635CC7;
      }
    }
  }
}


/* .textBox > img{
  height: 80px;
} */

.note{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  width: 8vw;
  height: 10vw;
  text-decoration:none;
  color:#000;
  background:#ffc;
  display:block;
  padding:1em;
  -moz-box-shadow:5px 5px 7px rgba(33,33,33,1);
  -webkit-box-shadow: 5px 5px 7px rgba(33,33,33,.7);
  box-shadow: 5px 5px 7px rgba(33,33,33,.7);
  transform:rotate(-6deg);

  p {
    font-family: 'Shadows Into Light', arial, sans-serif;
  }
}

.tablet {
  position: relative;
  width: 30vw;
  height: 50vw;
  margin: auto;
  border: 16px black solid;
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px;

  &:before {
    content: '';
    display: block;
    width: 60px;
    height: 5px;
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 10px;
  }
  &:after {
    content: '';
    display: block;
    width: 35px;
    height: 35px;
    position: absolute;
    left: 50%;
    bottom: -65px;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 50%;
  }
}

// Monitor
/* .monitor {
  position: absolute;
  width:515px;
  height: 300px;
  border: solid 1em #333;
  border-radius:.5em;
  background:#ffc;
}
.monitor::-webkit-scrollbar {
      width: 15px;
}
.monitor::-webkit-scrollbar-thumb {
  background: #666;
}
::-webkit-scrollbar-track {
      background-color: #888;
}*/


.element{
  position: absolute;
}

.shelf{
  width: 597px;
  height: 160px;
  top: 84px;
  left: 160px;
}
/* .HueSaturation-1 {
  width: 1920px;
  height: 1080px;
  padding: 59px 0 0 160px;
  object-fit: contain;
} */
.asztalarnyek {
  width: 1486px;
  height: 467px;
  top: 795px;
  left: 828px;
  background-image: url("/images/png/asztalarnyek.png");
  background-position: 80px 0;
}
.desk {
  width: 1178px;
  height: 570px;
}
.lamp{
  width: 195px;
  height: 369px;
  top: 441px;
  left: 885px;
  z-index: 20;
  //box-shadow: 3.7px -1.5px 0 0 rgba(0, 0, 0, 0.13);
}

.flower{
  width: 145px;
  height: 207px;
  top: 614px;
  left: 945px;
  //box-shadow: 3.7px -1.5px 0 0 rgba(0, 0, 0, 0.13);
  z-index: 99;
}

.speaker-l{
  width: 130px;
  height: 180px;
  top: 624px;
  left: 1055px;
}

.speaker-r {
  width: 128px;
  height: 177px;
  top: 624px;
  left: 1631px;
}

.monitor{
  width: 370px;
  height: 301px;
  top: 508px;
  left: 1223px;
  padding: 13px 14px 94px;
  background-image: url("/images/png/szamitogep.png");
  //box-shadow: 3.7px -1.5px 0 0 rgba(0, 0, 0, 0.13);
}

.books{
  width: 219px;
  height: 49px;
  top: 761px;
  left: 1749px;
}

.coffee{
  width: 90px;
  height: 63px;
  top: 705px;
  left: 1821px
}

.chair{
  width: 444px;
  height: 695px;
  top: 681px;
  left: 1223px;
}

.plant{
  width: 373px;
  height: 538px;
  top: 659px;
  left: 429px;
  z-index: 11;
}

.plantBig {
  width: 332px;
  height: 600px;
  top: 566px;
  left: 2505px;
}

.wallGrid {
  width: 368px;
  height: 538px;
  top: 76px;
  left: 1661px;
  z-index: -1;
}

.palmphoto{
  width: 73px;
  height: 145px;
  top: 258px;
  left: 1728px;
}

.pens{
  width: 98px;
  height: 129px;
  top: 458px;
  left: 1919px;
}

.simpleFrameContainer{
  width: 282px;
  height: 120px;
  top: 122px;
  left: 1702px;
  background-image: url("/images/png/kepkeret-7@3x.png");
  background-size: contain;
  padding: 25px;
  text-align: center;

  span{
    font-family: AmaticSC;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3c3c3b;
    .bigger{
      font-size: 35px;
    }
  }
}

.contactContainer{
  width: 165px;
  height: 248px;
  background-image: url("/images/png/kapcsolatform@3x.png");
  background-size: contain;
  padding: 0;
  .emailSuccessText{
    font-size: 16px;
  }
  .contactMain{
    position: relative;
    padding: 50px 20px 20px 10px;
    margin: 16px 4px 0 16px;
    object-fit: contain;
    font-family: AmaticSC;
    font-size: 27px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: center;
    color: #3c3c3b;
  }
  img{
    width: 111px;
    height: 53px;
  }
  form{
    position: absolute;
    top: 25px;
    width: 137px;
    left: 7px;
    display: flex;
    flex-direction: column;
    padding: 0 20px 20px 10px;
    margin: 16px 4px 0 16px;
    h4{
      margin-bottom: 4px;
      margin-top: 0;
      font-family: AmaticSC;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.18;
      letter-spacing: normal;
      text-align: center;
      color: #006b96;
    }
  }
}

form {
  span{
    margin-bottom: 8px;
    font-family: Teko;
    font-size: 6px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.93;
    letter-spacing: normal;
    text-align: center;
    color: #000105;
  }
  select {
    width: 70px;
    height: 14px;
    font-size: 6px;
    border-radius: 0;
    border: solid 1px #6b3b87;
    margin-bottom: 5px;
    background-color: #6b3b87;
    color: #fff;
    &:focus{
      border: none;
      outline:none;
    }
    option {
      font-size: 22px;
    }
  }
  input, textarea {
    width: 100%;
    font-size: 6px;
    opacity: .7;
    display: block;
    flex: 1;
    padding: 3px;
    margin-bottom: 5px;
    border: solid 1px #6b3b87;
    background-color: #EAEAEC;
    &::placeholder{
      font-size: 6px;
      color: #006b96;
      font-weight: bold;
      text-transform: uppercase;
    }
    &[type='submit']{
      width: 40px;
      margin: 0 auto;
      box-shadow: -1px 1.1px 0 0 rgba(14, 70, 92, 0.44);
      background-color: #4fbce6;
      font-family: Teko;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      font-size: 8px;
      letter-spacing: normal;
      text-align: center;
      color: #6b3b87;
      border: none;
    }
  }
  input{
    /* &:nth-child(1){
      border-color: #FFB000;
    }
    &:nth-child(2){
      border-color: #BF0020;
    } */
    outline: 0;
    border-style: solid;
    border-width: 1px;
  }
  textarea {
    resize: none;
    border-radius: 0;
    //border-color: #00A97C;
    &:focus{
      border: solid 1px #6b3b87;
      outline:none;
    }
  }
}


.contactMain {
  transition: all 0.5s ease-out;
}

.languageSelector{
  position: absolute;
  left: 2153px;
  top: 487px;
  width: 69px;
  height: 71px;
  object-fit: contain;
  font-size: 31px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: left;
  color: #b99476;
}

.clock {
  position: absolute;
  width: 162px;
  height: 162px;
  top: 76px;
  left: 2153px;
  //box-shadow: 3.7px -1.5px 0 0 rgba(0, 0, 0, 0.13);
}

.modalOpener{
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #fff !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
  -webkit-box-shadow: 0px 0px 15px 4px rgba(0,0,0,0.5); 
  box-shadow: 0px 0px 15px 4px rgba(0,0,0,0.5);
  cursor: pointer;
  :hover{
    width: 30px;
    height: 30px;
    transition: 0.5s ease-out;
  }
}

#container.isMobile {
  .landingModal {
    p {
      font-size: 1em !important;
    }
  }
}

.landingModal{
  z-index: 9999999 !important;
  p {
    font-family: 'Montserrat';
    font-size: 24px;
  }
  .modal{
    overflow: scroll;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #CCCCCC;      /* color of the tracking area */
    }
  }
  form, .contact {
    position: relative;
    input, textarea, span, label {
      font-size: 20px;
      padding: 5px;
      &::placeholder{
        font-size: 20px;
      }
    }
    input[type='submit']{
      width: 100px;
      font-size: 24px;
      margin-top: 10px;
      margin-bottom: 20px;
      cursor: pointer;
    }
  }
}