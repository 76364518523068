.switcher{
  width: 155px;
  height: 140px;
  top: 455px;
  left: 2057px;
  cursor: pointer;
  //box-shadow: 3.7px -1.5px 0 0 rgba(0, 0, 0, 0.13);
  background-image: url('../images/switcher_up.svg');
  background-size: contain;
  background-repeat: no-repeat;
}

.light {
  top: 549px;
  left: 1025px;
  border-radius: 50%;
  position: absolute;
  background-color: #fff07c;
  width: 1px;
  height: 1px;

}

.light:before {
  content: "";
  z-index: 199;
  position: absolute;
  width: 77px;
  height: 0;
  border-bottom: 261px solid rgba(255, 240, 124, 0.5);
  border-right: 90px solid transparent;
  border-left: 25px solid transparent;
  transition: .3s;
  top: -16px;
  left: -22px;
  transform: skew(-2deg) rotate(-15deg);
  opacity: 0;
}

.light:after {
  content: "";
  z-index: 199;
  position: absolute;
  height: 0;
  border-bottom: 50px solid rgba(255, 240, 124, 0.5);
  border-right: 38px solid transparent;
  border-left: 191px solid transparent;
  transition: .3s;
  top: 216px;
  left: 6px;
  transform: rotate(0deg);
  opacity: 0;
}

#lightButton {
  content: "";
  position: absolute;
  top: 778px;
  left: 929px;
  width: 15px;
  height: 12px;
  background-color: #BF0020;
  border-radius: 5px 5px 0 0;
  transition: .2s;
  transform-origin: bottom;
  cursor: pointer;
  z-index: 21;
}

input#light {
  display: none;
}

#lightButton.on {
  transform: scaleY(0.65);
}

#lightButton.on + .light:before {
  opacity:1;
}
#lightButton.on + .light:after {
  opacity:1;
}

#lightSwitchContainer.on {
  //box-shadow:0 2px 2px #d0b57b;
  background-image: url('../images/switcher_down.svg');
  .switch {
    box-shadow:0 10px 10px -5px rgba(233,219,176,1), 0 0 0 1px rgba(0,0,0,.1), 0 0 0 4px #fff4d3,0 0 0 5px rgba(0,0,0,.1);
    &:before {
      height:35px;
      top:0px;
      line-height:35px;
      background:#fff4d3;
      color:#a4441a;
      text-shadow: 0 0 3px #ff4e00;
    }
    &:after {
      height:35px;
      bottom:5px;
      line-height:30px;
      background:#fffaea;
      box-shadow:0 5px 0 #d0b57b;
      color:#d7bf95;
      text-shadow:0 0 0px transparent;
    }
  }
}
