@font-face {
  font-family: 'Shadows Into Light';
  src: url("../fonts/ShadowsIntoLight-Regular.ttf") format("truetype");
}
/* 
@font-face {
  font-family: 'Bocadillo-italic';
  src: local('Bocadillo Italic'), local('Bocadillo-Italic'),
      url('../fonts/Bocadillo-Italic.woff2') format('woff2'),
      url('../fonts/Bocadillo-Italic.woff') format('woff'),
      url('../fonts/Bocadillo-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
} */

@font-face {
  font-family: 'Bocadillo';
  src: url("../fonts/Bocadillo.otf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: 'Teko';
  src: url("../fonts/Teko-Medium.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: 'Teko';
  src: url("../fonts/Teko-Bold.ttf") format("truetype");
  font-weight: bold;
}
/* @font-face {
  font-family: 'AmaticSC';
  src: url("../fonts/AmaticSC-Regular.ttf") format("truetype");
  font-weight: normal;
} */
@font-face {
  font-family: 'AmaticSC';
  src: url("../fonts/AmaticSC-Bold.ttf") format("truetype");
  font-weight: bold;
}