#modalWrapper{
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #DCDCDE, $alpha: 0.4);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  .modal{
    padding: 20px;
    background-color: #fff;
    text-align: center;
    border: 6px solid #FFB000;
    font-family: Teko;
    font-size: 24px;
    color: #3c3c3b;
    font-weight: normal;
    max-width: 90vw;
    max-height: 90vh;
    pre{
      font-size: 14px;
      white-space: normal;
    }
    button{
      margin: 0 auto;
      box-shadow: -1px 1.1px 0 0 rgba(14, 70, 92, 0.44);
      background-color: #4fbce6;
      //font-family: Teko;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      font-size: 22px;
      letter-spacing: normal;
      text-align: center;
      color: #6b3b87;
      border: none;
      padding: 8px;
      cursor: pointer;
    }
  }
}