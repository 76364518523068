.swtWrapper {
  cursor: pointer;
  height: 100%;
  width: 100%;
  pointer-events: auto;
  inset: 0px;
  overflow: hidden;
  position: absolute;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  mix-blend-mode: hard-light;
  .flashLight{
    position: absolute;
    border-radius: 4px;
    background-color: gray;
    opacity: 1;
    pointer-events: auto;
    transition: opacity 0.2s ease 0s;
  }
}
.isMobile .stdBox{
  font-size: 7px;
}
.stdBox{
  position: absolute;
  z-index: 99999;
  font-size: 18px;
  &.alwaysVisible{
    .tooltipText{
      opacity: 1 !important;
    }
  }
  .pinner {
    background-color: transparent;
    border: 0px;
    border-radius: 0px;
    color: rgb(85, 85, 85);
    cursor: pointer;
    font-size: 16px;
    line-height: 1;
    padding: 8px;
    appearance: none;
    display: inline-block;
    height: 36px;
    position: relative;
    width: 36px;
    z-index: 100;
    .innerPinner1 {
      animation: 1.2s ease-in-out 0s infinite normal none running joyride-beacon-inner;
      background-color: rgb(255, 0, 68);
      border-radius: 50%;
      display: block;
      height: 50%;
      left: 50%;
      opacity: 0.7;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 50%;
    }
    .innerPinner2 {
      animation: 1.2s ease-in-out 0s infinite normal none running joyride-beacon-outer;
      background-color: rgba(255, 0, 68, 0.2);
      border: 2px solid rgb(255, 0, 68);
      border-radius: 50%;
      box-sizing: border-box;
      display: block;
      height: 100%;
      left: 0px;
      opacity: 0.9;
      position: absolute;
      top: 0px;
      transform-origin: center center;
      width: 100%;
    }
  }
}

@keyframes joyride-beacon-inner {
  20% {
    opacity: 0.9;
  }

  90% {
    opacity: 0.7;
  }
}

@keyframes joyride-beacon-outer {
  0% {
    transform: scale(1);
  }

  45% {
    opacity: 0.7;
    transform: scale(0.75);
  }

  100% {
    opacity: 0.9;
    transform: scale(1);
  }
}

.tooltipText {
  position:relative;
  display: block;
  background:#fff;
  left: -50%;
  left: calc(-50% + 20px);
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  color: #3c3c3b;
}
.tooltipText:after {
  content: "";
  position: absolute;
  left: calc(50% - 13px);
  top: -20px;
  transform: rotate(90deg);
  border: 10px solid #fff;
  border-color: transparent #fff transparent transparent;
}
.tooltipText:after {
  display:block;
}
.tooltipText:after {
  opacity:1;
}

.zoomLevel .tooltipText{
  font-size: 8px;
}
